/* Arrows */
.swiper-button-next, .swiper-button-prev {
  color: #ccc; /* Change arrow color to red */
}

/* Pagination Dots */
.swiper-pagination-bullet {
  background-color: #ccc; /* Change color of the dots */
}

.swiper-pagination-bullet-active {
  background-color: #4f5a5e; /* Change color of the active dot */
}
